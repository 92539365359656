.name{
    color: var(--gray-scale-900, #404040);
text-align: center;
font-feature-settings: 'liga' off;

/* Web/Heading/Medium/small */
font-family: Rubik;
font-style: normal;
font-weight: 500;
line-height: 36px; /* 180% */
}

.title{
    color: var(--gray-scale-600, #8C8C8C);
text-align: center;
font-feature-settings: 'liga' off;

/* Web/Paragraph/Medium/medium */
font-family: Plus Jakarta Sans;
font-style: normal;
font-weight: 500;
line-height: 24px; /* 150% */
}

.description{
    color: var(--gray-scale-700, #737373);
text-align: center;
font-feature-settings: 'liga' off;

/* Web/Paragraph/Regular/large */
font-family: Plus Jakarta Sans;
font-style: normal;
font-weight: 400;
line-height: 32px; /* 177.778% */
}

.AboutUs{
    color: var(--gray-scale-900, #404040);
text-align: center;
font-feature-settings: 'liga' off;
/* Web/Heading/Semi Bold/xxlarge */
font-family: Rubik;
font-style: normal;
font-weight: 600;
line-height: 72px; /* 150% */
}

.containerAboutUs{
    background-image:  url('../../assets/landing/backgroundAboutUs.svg');
}

