.buttonForm{
    border-radius: 4px;
background: var(--bag-chatter-colors-accent-1-scale-accent-1-selected, #89C96A) !important;

/* Button enabled */
box-shadow: 2px 4px 16px 0px rgba(0, 0, 0, 0.08) !important;
}

.title1{
    color: var(--bag-chatter-colors-accent-1-scale-accent-1-selected, #89C96A);
font-feature-settings: 'liga' off;
font-family: Plus Jakarta Sans;
font-size: 24px;
font-style: normal;
font-weight: 600;
line-height: 24px; /* 100% */
}

.title2{
    color: var(--gray-scale-900, #404040);
font-feature-settings: 'liga' off;
font-family: Rubik;
font-size: 56px;
font-style: normal;
font-weight: 500;
line-height: 56px; /* 100% */
}

.title3{
    color: var(--gray-scale-600, #8C8C8C);

font-feature-settings: 'liga' off;
font-family: Plus Jakarta Sans;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: 24px; /* 133.333% */
letter-spacing: 0.36px;
}

.descriptionButton{
    color: var(--bag-chatter-colors-foreground-scale-foreground-selected, #61B889);
font-feature-settings: 'liga' off;
font-family: Rubik;
font-size: 20.374px;
font-style: normal;
font-weight: 500;
line-height: 30.561px; /* 150% */
}

.boldDescriptionButton{
    color: var(--bag-chatter-colors-foreground-scale-foreground-selected, #61B889);
font-feature-settings: 'liga' off;
font-family: Rubik;
font-size: 20.374px;
font-style: normal;
font-weight: 700;
line-height: 30.561px;
text-transform: uppercase;
}

@media (max-width: 765px) {
    .title1{
        display: block;
        color: var(--bag-chatter-colors-accent-1-scale-accent-1-selected, #89C96A);
text-align: center;
font-feature-settings: 'liga' off;

/* Mobile/Paragraph/Semi Bold/medium */
font-family: Plus Jakarta Sans;
font-size: 12px;
font-style: normal;
font-weight: 700;
line-height: 18px; /* 150% */

    }

.title2{
    display: block;
    color: var(--gray-scale-900, #404040);
text-align: center;

/* Mobile/Heading/Medium/xxlarge */
font-family: Rubik;
font-size: 32px;
font-style: normal;
font-weight: 500;
line-height: 40px; /* 125% */
}
  
.title3{
    color: var(--gray-scale-600, #8C8C8C);
text-align: center;
font-feature-settings: 'liga' off;
font-family: Plus Jakarta Sans;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 24px; /* 200% */
}

.descriptionButton{
    color: var(--bag-chatter-colors-foreground-scale-foreground-selected, #61B889);
font-feature-settings: 'liga' off;
font-family: Rubik;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 24px; /* 171.429% */
}

.boldDescriptionButton{
    color: var(--bag-chatter-colors-foreground-scale-foreground-selected, #61B889);
font-feature-settings: 'liga' off;
font-family: Rubik;
font-size: 14px;
font-style: normal;
font-weight: 700;
line-height: 24px;
text-transform: uppercase;
}

.arrowImage{
    width: 40px;
    height: 40px;
}
}