.faqQuestion{
    color: var(--gray-scale-900, #404040);
font-feature-settings: 'liga' off;

/* Web/Heading/Semi Bold/small */
font-family: Rubik;
font-style: normal;
font-weight: 600;
 /* 180% */
}


.faqAnswer2{
    color: var(--gray-scale-900, #404040);
font-feature-settings: 'liga' off;

/* Web/Paragraph/Medium/medium */
font-family: Plus Jakarta Sans;
font-style: normal;
font-weight: 500; /* 150% */
}

.title{
    color: var(--gray-scale-900, #404040);
text-align: center;

/* Mobile/Heading/Semi Bold/xlarge */
font-family: Rubik;
font-style: normal;
font-weight: 600;
line-height: 32px; /* 133.333% */
}

.arrow{
    color: #89C96A;
}

