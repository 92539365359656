.title{
    color: var(--gray-scale-100, #FFF);
font-feature-settings: 'liga' off;

/* Web/Heading/Medium/xlarge */
font-family: Rubik;
font-style: normal;
font-weight: 500;
line-height: 56px; /* 155.556% */
}

.description{
    color: var(--gray-scale-100, #FFF);
text-align: center;
font-feature-settings: 'liga' off;
/* Web/Paragraph/Regular/large */
font-family: Plus Jakarta Sans;
font-style: normal;
font-weight: 400;
line-height: 32px; /* 177.778% */
}