
.primaryTitle{
    display: flex;
    justify-content: center;
}
.primaryTitle span{
    color: var(--gray-scale-900, #404040);
text-align: center;
font-feature-settings: 'liga' off;

/* Web/Heading/Semi Bold/xxlarge */
font-family: Rubik;
font-style: normal;
font-weight: 600;
line-height: 72px; /* 150% */
}

.secondaryTitle{
    color: var(--gray-scale-900, #404040);
font-feature-settings: 'liga' off;

/* Web/Heading/Semi Bold/xlarge */
font-family: Rubik;
font-style: normal;
font-weight: 600;
line-height: 56px; /* 155.556% */
}

.secondaryDescriptionTitle{
    color: var(--gray-scale-700, #737373);
font-feature-settings: 'liga' off;
font-family: Plus Jakarta Sans;
font-size: 24px;
font-style: normal;
font-weight: 400;
line-height: 36px; /* 150% */
letter-spacing: 0.72px;
}

.mosaico1 {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;  /* 2 * 300px de ancho + un poco de margen */
    z-index: 1;

}

.cuadro {
    display: flex;
    padding: 24px 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    flex-shrink: 0;
    border-radius: 4px;
    border: 1px solid var(--gray-scale-300, #D9D9D9);
    background: var(--gray-scale-100, #FFF);
    box-shadow: 4px 4px 15px 0px rgba(0, 0, 0, 0.08);
}


.imageContainer1{
    z-index: 2;
}

.imageMosaico2{
    flex-shrink: 0;

}

.imageContainer2{
    z-index: 2;
}

.mosaico2 {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;  /* 2 * 300px de ancho + un poco de margen */
    position: relative;
    z-index: 3;

}

.secondaryTitleDescription{
  color: var(--gray-scale-700, #737373);
  font-feature-settings: 'liga' off;
  font-family: Plus Jakarta Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px; /* 150% */
  letter-spacing: 0.72px;

}

.titleStep{
    color: var(--gray-scale-900, #404040);
font-feature-settings: 'liga' off;

/* Web/Heading/Medium/small */
font-family: Rubik;
font-style: normal;
font-weight: 500;

}

.descriptionStep{
    color: var(--gray-scale-700, #737373);
font-feature-settings: 'liga' off;

/* Web/Paragraph/Regular/medium */
font-family: Plus Jakarta Sans;
font-style: normal;
font-weight: 400; /* 150% */
}

@keyframes fadeInGroup1 {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @keyframes fadeInGroup2 {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  /* Estilo base para los cuadros */
  .cuadro {
    /* ... otros estilos ... */
    opacity: 0;
  }
  
  /* Aplicar animación al primer grupo de cuadros */
  .grupo1 {
    animation: fadeInGroup1 1s ease-in-out forwards;
  }
  
  /* Aplicar animación al segundo grupo de cuadros con un retraso de 1 segundo */
  .grupo2 {
    animation: fadeInGroup2 1s ease-in-out forwards;
    animation-delay: 1s; /* Retraso de 1 segundo para el segundo grupo */
  }
  

