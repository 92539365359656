.title{
    color: var(--gray-scale-900, #404040);
font-feature-settings: 'liga' off;

/* Web/Heading/Medium/large */
font-family: Rubik;
font-style: normal;
font-weight: 500;
line-height: 40px; /* 142.857% */
}

.description{
    color: var(--gray-scale-700, #737373);
font-feature-settings: 'liga' off;
font-family: Plus Jakarta Sans;
font-style: normal;
font-weight: 400;
line-height: 36px; /* 150% */
letter-spacing: 0.96px;
}