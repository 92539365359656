.imageWrapper {
    width: 100%; /* Ajusta esto al alto que quieras que tenga el contenedor */
    overflow: hidden;
    position: relative;
}

.imageWrapper img {
    width: 141%; /* Aumentar el ancho ayuda a cubrir el área inclinada */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) skewY(2.57deg); /* El valor -26.57deg suele ser adecuado para una inclinación de esquina a esquina */
}

